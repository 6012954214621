const URL = process.env.VUE_APP_URL_BASE_CMS
const headers = {
   'Content-Type': 'application/json',
   'Accept': 'application/json',
   'x-function-key': process.env.VUE_APP_CODE_BASE_PRIVATE
 }

 export function getPatients () {
    return window.axios({
      method: 'get',
      url: URL+'patients',
      headers: headers
    })
}

export function postPatients (cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL+'patients',
    data: {},
    headers: headers
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function getShifts () {
  return window.axios({
      method: 'get',
      url: URL+'shifts',
      headers: headers
    })
}

export function getLab() {
  return window.axios({
      method: 'get',
      url: URL+'labres',
      headers: headers
    })
}

export function getVSign () {
  return window.axios({
      method: 'get',
      url: URL+'vsign',
      headers: headers
    })
}

export function postVSign (data, cb, errorCb) {
  window.axios({
    method: 'post',
    url: URL+'vsign',
    headers: headers,
    data: data
  }).then(response => {
    cb(response)
  }).catch(error => {
    errorCb(error)
  })
}

export function postShifts (data, cb, errorCb) {
    window.axios({
      method: 'post',
      url: URL+'shifts',
      headers: headers,
      data: data
    }).then(response => {
      cb(response)
    }).catch(error => {
      errorCb(error)
    })
}